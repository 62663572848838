import React from 'react'
import './Home.css'
import MenuBar from '../component2/MenuBar'
import CompoCards from '../component2/CompoCards'
import InfoCard from '../component2/InfoCard'
import SlideCards from '../component2/SlideCards'
import Slider from '../component2/Slider'
import Demo from '../component2/demo'
import Brands from '../component2/Brands'

export default function Homee() {
  return (
    <div>
      <div className='container'>
        <div className='row '>

<div className='col-lg-8'>
<div id="carouselExampleCaptions" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://media.istockphoto.com/id/944478708/photo/couple-eating-lunch-with-fresh-salad-and-appetizers.jpg?s=612x612&w=0&k=20&c=xZdIIHvakQrYCbR59RM8nrhEnw-xu4nE-BOeOhQPnck=" class="d-block w-100" alt="..." style={{height:"400px",borderRadius:"10px"}}/>
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div class="carousel-item">
    <img src="https://media.istockphoto.com/id/1279763992/photo/healthy-food-for-lower-cholesterol-and-heart-care-shot-on-wooden-table.jpg?s=612x612&w=0&k=20&c=AciLLDoSmYjWZlCwmFiFu8L_wbOmY8ZBxy9y_pLaiGw=" alt="..." style={{height:"400px",width:"100%",borderRadius:"10px"}}/>

      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div class="carousel-item">
    <img src="https://media.istockphoto.com/id/1175505781/photo/arabic-and-middle-eastern-dinner-table-hummus-tabbouleh-salad-fattoush-salad-pita-meat-kebab.jpg?s=612x612&w=0&k=20&c=N4PkdbA7Bf-WNKf2VRNz9mtZP4sxrdcsMwZ7P981ZIY=" class="d-block w-100" alt="..." style={{height:"400px",borderRadius:"10px"}}/>

      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
{/* carousal code  */}
</div>     {/*col div end here*/}

<div className='col-lg-4'>
    <div>
      <h2 class="fade-in-text" style={{color:"#dd5c89"}}><b>Welcome to Chavraj Caterers </b></h2>
      <p class="fade-in-text" style={{textAlign:"left",fontFamily:"inherit"}}>&nbsp; &nbsp;&nbsp;There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
    </div>
</div>
{/* carousal code  */}


<Brands/>
{/* <CompoCards/> */}
<InfoCard/>

{/* <SlideCards/> */}
{/* <Slider/> */}


        </div>
      </div>
    </div>
  )
}
