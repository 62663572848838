import React from 'react';
import './Slide.css'; // Ensure this file has the necessary styles
import BasinCards2 from './BasinCards2';
import { Link } from 'react-router-dom';

export default function WashBasinCard() {
    
  return (
    <>
      <div className='container'>
        <div className='row mt-4 d-flex justify-content-center'> 
        <h3 style={{color:"#ff004f",textAlign:"center"}}>
        Delicious Menus
                </h3>
          <div className='col mb-2'>
            <div className='card' style={{ width: '22rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/530417618/photo/baked-salmon-garnished-with-asparagus-and-tomatoes-with-herbs.jpg?s=612x612&w=0&k=20&c=eMT5EFd7EzQgaft2Rq36Piy6qH_kV_m7_m-kjyUe8yQ=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/1365881693/photo/children-blowing-birthday-candles.jpg?s=612x612&w=0&k=20&c=iSrLula9Db0uKrQ7-nPuzcX42WhGXh64TzHJc58w3r8=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/947221076/photo/birthday.jpg?s=612x612&w=0&k=20&c=Xu8vJ_JGNTZ1sRkZmJBOnVFmkh9tG1To9HEL-otnezc=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Serif",color:"#ff004f"}}>Combo-Pack</h3>
               <p  style={{ fontFamily: "Serif",color:"#FF5733",fontWeight:"600",fontSize:"20px"}}>Series:Kubix Prime</p>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: ARC-87213B</p>
              
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}><b>Product: </b>Single Lever Exposed Parts Kit of Hi-flow Diverter Consisting of Operating Lever</p>
                <p class="card-text" style={{color:"#b31c6e",margin:"0",padding:"0" ,fontSize:"20px",fontWeight:"600"}}>Price:<i class="bi bi-currency-rupee"></i>19,125</p>
                
                <Link to={'/Basindet'} class="btn btn-outline-warning"style={{marginTop:"5px"}}>View Product Detail</Link>
              </div>
            </div>
          </div>             {/* col div end here*/}

          <div className='col mb-2'>
            <div className='card' style={{ width: '22rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/1158602358/photo/indian-hindu-veg-thali-food-platter-selective-focus.jpg?s=612x612&w=0&k=20&c=YHu-1cg7yuaDTI68yrRzaKfhWAneAis0fAvj_-MQcTQ=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/1149225861/photo/birthday-part-zone-with-pink-silver-turquoise-baloons-and-birthday-cake.jpg?s=612x612&w=0&k=20&c=GUdKlps3bnAb0QQwUTtozUbl71KkRZhxHiYa_cp9sR0=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/1364590089/photo/welcome-happy-birthday-party-sign-written-on-the-chalkboard.jpg?s=612x612&w=0&k=20&c=PTAuNB-FZMDGbYSLDW2hq0A5yoHyH8UtM56tUQLcKeg=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Serif",color:"#ff004f"}}>Veg Thali</h3>
               <p  style={{ fontFamily: "Serif",color:"#FF5733",fontWeight:"600",fontSize:"20px"}}>Series:Vignette Prime</p>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: DRC-37083K</p>

                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Single Lever Basin Mixer Wall Mounted Consisting stick and screw fitting.</p>
                <p class="card-text" style={{color:"#b31c6e",margin:"0",padding:"0" ,fontSize:"20px",fontWeight:"600"}}>Price:<i class="bi bi-currency-rupee"></i>14,550</p>
                
                <Link to={'/Basindet'} class="btn btn-outline-warning" style={{marginTop:"5px"}}>View Product Detail</Link>
              </div>
            </div>
          </div>             {/* col div end here*/}

          <div className='col '>
            <div className='card' style={{ width: '22rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleInterval3" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/1319973261/photo/people-hands-toasting-multicolored-fancy-drinks-young-friends-having-fun-together-drinking.jpg?s=612x612&w=0&k=20&c=_laIepgjipbjSO_E-6KD-hGvJuRl-qb6M60rB946Tvg=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/972654870/photo/780298633.jpg?s=612x612&w=0&k=20&c=ma3LfkFBhJbqpEi4FbdjSrMqr0RsRKarlgzrbIThMok=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/1249204744/photo/green-peas-cream-soup.jpg?s=612x612&w=0&k=20&c=5BQAg4k5ucVSrZ8SgfL4TvWseU4H75KHcLeIKdt-U6g=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval3" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleInterval3" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Serif",color:"#ff004f"}}>Beverages</h3>
               <p  style={{ fontFamily: "Serif",color:"#FF5733",fontWeight:"600",fontSize:"20px"}}>Series:Continental</p>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve  with Fitting Sleeve</p>
                <p class="card-text" style={{color:"#b31c6e",margin:"0",padding:"0" ,fontSize:"20px",fontWeight:"600"}}>Price:<i class="bi bi-currency-rupee"></i>9,799</p>
                <Link to={'/Basindet'} class="btn btn-outline-warning" style={{marginTop:"5px"}}>View Product Detail</Link>
              </div>
            </div>
          </div>             {/* col div end here*/}
        </div>             {/* row div end here*/}
      </div>           {/* row div end here*/}


      <BasinCards2/>
    </>
  );
}
