import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
   <>
          <div style={{ height: "80px", backgroundColor: "#f5adc6 ", marginTop: "10px" }}>
              <div className='container' >
                  <div className='row mt-4' >
                      <div className='col d-flex justify-content-evenly mt-4'>
                          <h2 style={{color:"#720e4e",fontWeight:"800"}}>ChavRaj Caterers</h2>
                      </div>
                  </div>
              </div>
          </div>



          <div style={{ backgroundColor: "#eeeded " }}>
              <div className='container info ' >
                  <div className='row d-flex justify-content-center' >
                    <div className='col mt-1'>
                        <p>Register Today, Earn Tomorrow</p>
                        <p><i class="bi bi-geo-alt-fill"></i> Poonam Apartment, Sahakar Nagar shashtri nagar naka, Pokharan Rd Number 1, Thane, Maharashtra 400606</p>
                        <p><i class="bi bi-facebook"></i> https://www.facebook.com/chavrajcaterers</p>
                        <p><i class="bi bi-telephone"></i> 98199 53766</p>

                    </div>
        
                  </div>
              </div>
          </div>

          <div style={{ height: "50px", backgroundColor: "gray ", marginTop: "0px" }}>
              <div className='container' >
                  <div className='row' >
                      <div className='col d-flex justify-content-evenly mt-2 '>
                         
                          <h5  style={{color:"black"}}>Copyright &copy; 2024 Mauli Infotech(OPC) Pvt.Ltd.</h5>
                      </div>
                  </div>
              </div>
          </div>
   </>
  )
}
