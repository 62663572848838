import React from 'react'
import './conta.css'

export default function Conta() {
  const onSubmitForm=(event)=>{
    event.preventDefault()
    alert("Messge send sucessfully")
  }
  return (
    <div style={{ backgroundColor: "#eeeded", height: "100%", width: "100%" }}>
      <div className='container ' >
        <h1 style={{ textAlign: "center", fontWeight: "600", fontSize: "3rem", color: "#ea0c71", margin: "0px auto 0" }}>Contact Us</h1>
        <div className='row mb-5 m-3'>
          <h2 style={{ textAlign: "center", color: "black", margin: "0px auto 0", marginBottom: "15px" }}>Any questions or remarks? Just write us a message!</h2>
          <div className='col col-lg-6 col-md-12 col-12 mb-5' style={{ borderRadius: "25px", height: "630px", backgroundColor: "white", marginRight: "20px" }}>
            <img src='https://scontent.fbom2-1.fna.fbcdn.net/v/t39.30808-6/304378586_464108002397812_4547175944523755533_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=86c6b0&_nc_ohc=AAgTrTn1RLoQ7kNvgFEXMNg&_nc_ht=scontent.fbom2-1.fna&_nc_gid=ALkWw9-zJ3Caov-lAOq5kfD&oh=00_AYBFLU7IohtoIdkQH55TYDlEjDbiy9J-tA3S8D3cvgiOlA&oe=67015AEB' alt='' style={{ height: "400px", width: "92%", margin: "20px", alignItems: "center", borderRadius: "20px", marginTop: "30px" }}></img>

            <div className='col col-lg-4 col-12 col-md-6 d-flex justify-content-evenly' style={{ width: "100%" }}>
              <div>
                <h4>Address</h4>
                <h5> Poonam Apartment, Sahakar Nagar shashtri nagar naka, Pokharan Rd Number 1, Thane, Maharashtra 400606</h5>
                <p> 98199 53766</p>
              </div>
              
            </div>
           <div style={{marginTop:"10px",textAlign:"center"}}> <i style={{}}>Copyright by Mauli Infotech</i></div>
          </div>

          {/* second col start here */}

          <div className='col col-lg-5 col-md-12 col-12 mb-5' style={{ borderRadius: "25px", height: "630px", backgroundColor: "white" }}>
            <h1 style={{ textAlign: "center", color: "black", marginTop: "10px", marginBottom: "15px" }}>Get in Touch</h1>
            <h4 style={{ textAlign: "center" }}>Have an inquiry or some feedbak for us? Fill out the form below to contact our team.</h4>
         
            <div class="container" style={{borderRadius:"5px",backgroundColor:"#f2f2f2",padding:"20px"}}>
              <form onSubmit={onSubmitForm}>
                <label for="fname"> Name</label>
                <input type="text" id="fname" name="firstname" placeholder="Your name.." required/>

                  <label for="lname">email</label>
                  <input type="text" id="lname" name="lastname" placeholder="Your email.." />

                   

                    <label for="subject">Subject</label>
                    <textarea id="subject" name="subject" placeholder="Write something.." required style={{height:"100px"}}></textarea>

                    <input type="submit" value="Submit"/>
                    </form>
                  </div>

                </div>

            </div>

          </div>
        </div>
        )
}
