import React from 'react'
import { Link } from 'react-router-dom'
import './table.css'

export default function AddCart() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-8'>
            <div className='row mt-2'>
              <div className='col col-lg-3'>
                <img src="https://media.istockphoto.com/id/2165203432/photo/top-view-of-luxury-hotel-bathroom-interior-with-tub-sink-and-panoramic-window.jpg?s=612x612&w=0&k=20&c=m8C9CCHoVQJlVwqvU1VFquCjFi7gXdoIAbv6phtp3bg=" style={{ height: "140px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />

              </div>        {/* first col div ends here */}
              <div className='col col-lg-8'>
                <h5 style={{ color: "#ff004f" }}> haus & kinder Decorative Sham Throw Embroidered Wash Basins...</h5>
                <p style={{ fontFamily: "Poppins", color: "gray" }}>Multicolor</p>
                <p style={{ color: "#388e3c" }}><b>Special Offers</b></p>
                <div className=' d-flex justify-content-start'>
                  <h6><i class="bi bi-currency-rupee"></i>2,999</h6> &nbsp; &nbsp;
                  <h6 style={{ textDecoration: "line-through", color: "gray", marginTop: "3px" }}><i class="bi bi-currency-rupee"></i>4555</h6>&nbsp;&nbsp;
                  <p style={{ color: "#388e3c", marginTop: "5px" }}>72% off</p>

                </div>

              </div>          {/* first col div ends here */}
              {/* <div className='col col-lg-4'></div>         first col div ends here */}

            </div>         {/* first inner row div ends here */}

            <div className='row mt-2 mb-2'>
              <div className='col col-lg-2 d-flex justify-content-evenly'>
                <div className=' col d-flex justify-content-evenly' style={{ height: "25px", border: "0.5px solid gray", cursor: "pointer" }} >-</div>
                <div className='col  d-flex justify-content-evenly'>1</div>
                <div className='col d-flex justify-content-evenly' style={{ height: "25px", width: "10px", border: "0.5px solid gray", cursor: "pointer" }} >+</div>
              </div>

              {/* <div className='col col-lg-1'></div> */}
              <div className='col col-lg-5 d-flex justify-content-evenly'>
                <h6 style={{ cursor: "pointer" }}>SAVE FOR LATER</h6>
                <h6 style={{ cursor: "pointer" }}>REMOVE</h6>
              </div>
            </div>         {/* first inner row div ends here */}

            {/* Button div start here */}

            <div className='row mt-4'>
              <div className='d-flex justify-content-evenly'>
                <button type="button" style={{ height: "50px", width: "250px" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="btn btn-warning" > See More Like This</button>

                <button type="button" style={{ height: "50px", width: "250px" }} class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal" ><i class="bi bi-lightning-fill"></i>Get Quotation</button>

              </div>

            </div>
    {/* quotation modal start here */}
{/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Quotation Sheet</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div className='container'>
          <div className='row'>
            <p><b>Fantastic Trading Company</b></p>
            <p>Tax: 12%</p>
            <p>TaxNumber: 123 456 789</p>
            <p>Your Address: abcdefg hijklmn op</p>
            <p>Your Contact Number: 9876543210</p><hr></hr>
            <p><b>Bill To:</b></p>
            <p>Client Name: Abcd Efghi Jklmn</p>
            <p>Client Address: abcdefg hijklmn opqrst uvw xyz</p>
            <p>Your Contact Number: 9876543210</p>

            <table style={{textAlign:"left",border:"1px solid gray"}}>
  <tr>
    <th style={{textAlign:"left" ,border:"1px solid #dddddd"}}>Description</th>
    <th style={{textAlign:"left",border:"1px solid #dddddd"}}>Quantity</th>
    <th style={{textAlign:"left",border:"1px solid #dddddd"}}>Rate</th>
    <th style={{textAlign:"left",border:"1px solid #dddddd"}}>Amount</th>
  </tr>
  <tr>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>haus & kinder Decorative Sham Throw Embroidered Wash Basins</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>10</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>1550</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>15500</td>

  </tr>
  <tr>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>Ramya Galaxy Shower 4inch With 9inch Square Arm Chrome Finished Item Shower Head.</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>5</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>1800</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>9000</td>

  </tr>
  <tr>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}></td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}></td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}></td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}></td>

  </tr>
  <tr>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}></td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}></td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}>Total Amount</td>
    <td style={{textAlign:"left",border:"1px solid #dddddd"}}><b>24000</b></td>

  </tr>
</table>
<p style={{marginTop:"15px",marginBottom:"0px"}}>Note</p>
<span style={{height:"45px",border:"1px solid #dddddd"}}></span>

<p style={{marginTop:"15px",marginBottom:"0px"}}>Terms and Conditions</p>
<span style={{height:"200px",border:"1px solid #dddddd"}}></span>
          </div>        {/* row div ends here */}
        </div>
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">PRINT</button>
      </div>
    </div>
  </div>
</div>

{/* Quotation modal ends here */}
            {/* <!-- Button trigger modal --> */}

{/* Comparision modal start here */}
            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">More items like this</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {/* Table start here */}
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Item 1</th>
                    <th>Item 2</th>
                    <th>Item 3</th>
                    <th>Item 4</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td><img src="https://m.media-amazon.com/images/I/51tH9QBI9HL._AC_AA450_.jpg" style={{ height: "120px", width: "120px", borderRadius: "10px" }} alt="Item 1" /></td>
                    <td><img src="https://m.media-amazon.com/images/I/61Il3WCUN6L._AC_AA450_.jpg" style={{ height: "120px", width: "120px", borderRadius: "10px" }} alt="Item 2" /></td>
                    <td><img src="https://m.media-amazon.com/images/I/410TNOa+sOL._AC_AA450_.jpg" style={{ height: "120px", width: "120px", borderRadius: "10px" }} alt="Item 3" /></td>
                    <td><img src="https://m.media-amazon.com/images/I/71XWEi40q+L._AC_AA450_.jpg" style={{ height: "120px", width: "120px", borderRadius: "10px" }} alt="Item 4" /></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td><a href="#">KOHLER Mist Rainduet shower head for bathroom, 5-spray Multifunction shower</a></td>
                    <td><a href="#">ALTON SHR22340, ABS, 4-INCH Single Flow Overhead Shower</a></td>
                    <td><a href="#">KOHLER Mist Rainduet shower head for bathroom, 5-spray Multifunction shower</a></td>
                    <td><a href="#">ALTON SHR22340, ABS, 4-INCH Single Flow Overhead Shower</a></td>
                  </tr>
                  <tr>
                    <th>Customer Rating</th>
                    <td style={{ color: "#FFC300" }}><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></td>
                    <td style={{ color: "#FFC300" }}><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></td>
                    <td style={{ color: "#FFC300" }}><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></td>
                    <td style={{ color: "#FFC300" }}><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></td>
                  </tr>
                  <tr>
                    <th>Price</th>
                    <td><i className="bi bi-currency-rupee"></i>1200</td>
                    <td><i className="bi bi-currency-rupee"></i>1950</td>
                    <td><i className="bi bi-currency-rupee"></i>2290</td>
                    <td><i className="bi bi-currency-rupee"></i>5430</td>
                  </tr>
                  <tr>
                    <th>Sold By</th>
                    <td>Kohler</td>
                    <td>Jaquar</td>
                    <td>Kohler</td>
                    <td>Cora</td>
                  </tr>
                  <tr>
                    <th>Material</th>
                    <td>Stainless steel</td>
                    <td>Copper</td>
                    <td>Brass</td>
                    <td>Aluminium</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Table end here */}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Understood</button>
          </div>
        </div>
      </div>
    </div>
            {/*Cmparision Modal end here */}
            <hr></hr>

{/* ----------------------------------------------------------------------------------------- */}
            <div className='row mt-2'>
              <div className='col col-lg-3'>
                <img src="https://media.istockphoto.com/id/1400295846/photo/jets-of-clean-water-flowing-in-the-shower-cabin.jpg?s=612x612&w=0&k=20&c=NrfegYfTDWC8TzQbllUvABYo4iGWVKC171lCa4PR_Cw=" style={{ height: "140px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />

              </div>        {/* first col div ends here */}
              <div className='col col-lg-8'>
                <h5 style={{ color: "#ff004f" }}>Ramya Galaxy Shower 4inch With 9inch Square Arm Chrome Finished Item Shower Head...</h5>
                <p style={{ fontFamily: "Poppins", color: "gray" }}>Multicolor</p>
                <p style={{ color: "#388e3c" }}><b>Special Offers</b></p>
                <div className=' d-flex justify-content-start'>
                  <h6><i class="bi bi-currency-rupee"></i>2,999</h6> &nbsp; &nbsp;
                  <h6 style={{ textDecoration: "line-through", color: "gray", marginTop: "3px" }}><i class="bi bi-currency-rupee"></i>4555</h6>&nbsp;&nbsp;
                  <p style={{ color: "#388e3c", marginTop: "5px" }}>72% off</p>

                </div>

              </div>          {/* first col div ends here */}
              {/* <div className='col col-lg-4'></div>         first col div ends here */}

            </div>         {/* first inner row div ends here */}

            <div className='row mt-2 mb-2'>
              <div className='col col-lg-2 d-flex justify-content-evenly'>
                <div className=' col d-flex justify-content-evenly' style={{ height: "25px", border: "0.5px solid gray", cursor: "pointer" }} >-</div>
                <div className='col  d-flex justify-content-evenly'>1</div>
                <div className='col d-flex justify-content-evenly' style={{ height: "25px", width: "10px", border: "0.5px solid gray", cursor: "pointer" }} >+</div>
              </div>

              {/* <div className='col col-lg-1'></div> */}
              <div className='col col-lg-5 d-flex justify-content-evenly'>
                <h6 style={{ cursor: "pointer" }}>SAVE FOR LATER</h6>
                <h6 style={{ cursor: "pointer" }}>REMOVE</h6>
              </div>
            </div>         {/* first inner row div ends here */}

            {/* Button div start here */}

            <div className='row mt-4'>
              <div className='d-flex justify-content-evenly'>
              <button type="button" style={{ height: "50px", width: "250px" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="btn btn-warning" > See More Like This</button>

              <button type="button" style={{ height: "50px", width: "250px" }} class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal" ><i class="bi bi-lightning-fill"></i>Get Quotation</button>

              </div>

            </div>


          </div>        {/*first col div ends here */}
          {/* -------------------------- --------------------------------------------------------------------------------------- */}
          <div className='col col-lg-4' style={{ height: "450px", boxShadow: "5px 5px 10px gray", borderRadius: "10px" }}>
            <h5 style={{ color: "gray", margin: "10px 0" }}>PRICE DETAILS</h5><hr></hr>
            <div className='d-flex  justify-content-between'>
              <p className="d-flex justify-content-start" style={{ color: "black", fontFamily: "sans-serif" }}>Price(Two Items):</p>
              <p className="d-flex justify-content-end" ><i class="bi bi-currency-rupee"></i>9,110</p>
            </div>

            <div className='d-flex  justify-content-between'>
              <p className="d-flex justify-content-start" style={{ color: "black" }}>Discount:</p>
              <p className="d-flex justify-content-end" style={{ color: "#388e3c" }}><i class="bi bi-currency-rupee"></i>- 3,112</p>
            </div>

            <div className='d-flex  justify-content-between'>
              <p className="d-flex justify-content-start" style={{ color: "black" }}>Platform Fee:</p>
              <p className="d-flex justify-content-end" style={{ color: "black" }}><i class="bi bi-currency-rupee"></i>+3</p>
            </div>

            <div className='d-flex  justify-content-between'>
              <p className="d-flex justify-content-start" style={{ color: "black" }}>Delivery Charges:</p>
              <p className="d-flex justify-content-end" style={{ color: "gray" }}><i class="bi bi-currency-rupee"></i><span style={{ textDecoration: "line-through" }}>  40</span>  &nbsp;<span style={{ color: "#388e3c" }}>Free</span></p>

            </div>

            <hr></hr>
            <div className='d-flex  justify-content-between'>
              <p className="d-flex justify-content-start" ><b>Total Amount:</b></p>
              <p className="d-flex justify-content-end" ><b> <i class="bi bi-currency-rupee"></i> 5998</b></p>
            </div>
            <hr></hr>
            <h5 style={{ color: "#388e3c" }}>You will save ₹3,112 on this order</h5>
          </div>
        </div>           {/*row div ends here */}




      </div>          {/* container div ends here */}
    </>
  )
}
