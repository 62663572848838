import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSheetPlastic,faUsers,faCopyright,faPercent,faStar } from '@fortawesome/free-solid-svg-icons';

export default function AboutUs() {
  return (
    <div>
      <div className='container'>
        <div className='row'>
          {/* <h1 style={{ textAlign: "center", fontWeight: "600", fontSize: "3rem", color: "#ea0c71", margin: "0px auto 0",marginBottom:"10px" }}>About Us</h1> */}
          {/* ,display:"flex",alignItems:"center",justifyContent:"center" */}
          <div className='col col-lg-5 col-md-12 col-12'>

          <img src='https://scontent.fbom2-2.fna.fbcdn.net/v/t39.30808-6/449773426_905994031542538_3877424561761081613_n.png?_nc_cat=109&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=pww4jZj3SeIQ7kNvgEPnaSX&_nc_ht=scontent.fbom2-2.fna&_nc_gid=AVEzUSnq79aEn3rDB2pIqnT&oh=00_AYA_3UyIIh7qP5T3gJZWuwlRKhO5bOdCqsyxOEjXvQBLmA&oe=67006038'alt='' style={{height:"400px",width:"450px"}}/>
          
          </div>      {/* col 5 div end here*/}

          {/* ------------------------------------------------------------------------------------------------------ */}
          <div className='col col-lg-6 col-md-12 col-12' >

            <h3 style={{ color: "#ff004f",marginTop:"10px" }}>
              ABOUT US
            </h3>
            <p style={{ color: "gray", fontFamily: "Poppins", fontSize: "20px" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar luctus sem, eget porta orci. Maecenas molestie dui id diam feugiat, eu tincidunt mauris aliquam. Duis commodo vitae ligula et interdum. Maecenas faucibus mattis imperdiet. In rhoncus ac ligula id ultricies.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar luctus sem, eget porta orci. Maecenas molestie dui id diam feugiat, eu tincidunt mauris aliquam. Duis commodo vitae ligula et interdum. Maecenas faucibus mattis imperdiet. In rhoncus ac ligula id ultricies.

            </p>

          </div>     {/* col 6 div end here*/}

        </div>           {/* row div end here*/}
      </div>          {/* container div end here*/}
{/* -----------------------------------------Icons div start here------------------------------------------------------ */}
      <div className='d-flex justify-content-evenly' style={{marginTop:"10px"}}>
        <div className='row d-flex justify-content-evenly' style={{width:"100%"}}>
        <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faSheetPlastic} style={{ color: '#0ea6ad', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>5yrs+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Experience</h5>
            </div>
          </div>
          
          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faUsers} style={{ color: '#bce906', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>5000+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Clients</h5>
            </div>
          </div>

          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faCopyright} style={{ color: '#d50f90 ', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>10+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Brands</h5>
            </div>
          </div>

          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faPercent} style={{ color: '#0bb9d8 ', fontSize: '5rem',marginRight:"20px"}} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>10%</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Discounts</h5>
            </div>
          </div>

          <div className='col col-lg-2 col-md-4 m-3 col-10 d-flex justify-content-evenly' style={{border:"1px solid #f3f3f3",padding:"30px",borderRadius:"10px"}}>
           <div>
           <FontAwesomeIcon icon={faStar} style={{ color: '#FFC300 ', fontSize: '5rem',marginRight:"20px" }} />
           </div>
          <div>
            <h2 style={{fontWeight:"600",fontSize:"2.5rem"}}>4.5+</h2>
            <h5 style={{fontWeight:"400",fontSize:"1.25rem",color:"#415466"}}>Ratings</h5>
            </div>
          </div>

          
          </div>  
          </div>

{/* -----------------------------------------Icons div ends here------------------------------------------------------ */}

          <div className='container'>
              <div className='row mt-3'>

              <div className='col col-lg-6 col-md-12 col-12' style={{ marginRight: "80px" }} >

<h3 style={{ color: "#ff004f" }}>
  ABOUT OUR PRODUCTS
</h3>
<p style={{ color: "gray", fontFamily: "Poppins", fontSize: "20px" }}>
  &nbsp;&nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar luctus sem, eget porta orci. Maecenas molestie dui id diam feugiat, eu tincidunt mauris aliquam. Duis commodo vitae ligula et interdum. Maecenas faucibus mattis imperdiet. In rhoncus ac ligula id ultricies.
  <br></br>
  - Quality Trust
  <br></br>
  - Upto 10 years warrenty
  <br></br>
  - Available in all sizes
  <br></br>
  - Attractive colors
  <br></br>
  - Number of choices
  <br></br>
  - Reasonable rate
</p>

</div>     {/* col 6 div end here*/}



              <div className='col col-lg-5 col-md-12 col-12' >

<div className='row mt-3'>
  <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="https://media.istockphoto.com/id/1158616319/photo/indian-hindu-veg-thali-food-platter-selective-focus.jpg?s=612x612&w=0&k=20&c=n6tPlw3aHTzq6OnUEL7sSPeUdz90ZUbPqCboRim8stU=" style={{ height: "400px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="https://media.istockphoto.com/id/104704117/photo/restaurant-plates.jpg?s=612x612&w=0&k=20&c=MhFdN_qVgzoHov-kgFx0qWSW0nZht4lZV1zinC3Ea44=" style={{ height: "400px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="https://media.istockphoto.com/id/1347087219/photo/assortment-of-delicious-authentic-tacos-birria-carne-asada-adobada-cabeza-and-chicharone.jpg?s=612x612&w=0&k=20&c=8TJspKsshMc6QN8aBgnbaMgMwKKHZuLKRq8D_BYj5Tw=" style={{ height: "400px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>


  
</div>             {/* first row end here */}
{/* --------------------------------------------------------------------------- */}
</div>      {/* col 5 div end here*/}






              </div>
          </div>


        </div>    


   
  )
}
