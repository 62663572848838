import React from 'react'
import { Link } from 'react-router-dom'

export default function BasinCards2() {
    return (
        <>
            <div className='container'>
                <div className='row mt-4 d-flex justify-content-center'>
                <h3 style={{color:"#ff004f",textAlign:"center"}}>
                Other Menu Options
                </h3>
                   
                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "18rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/481765835/photo/homemade-italian-bruschetta-appetizer.jpg?s=612x612&w=0&k=20&c=20lme_vcpR4R2wfNyAFwvglvSj3mxJU9qel00LqzP3I=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Vignette Prime</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve wire.</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3  col-md-6 mb-2'>
                        <div class="card" style={{ width: "18rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/613115478/photo/foie-gras-and-cranberry-chutney.jpg?s=612x612&w=0&k=20&c=BiWik9zaYppnTVInXtUb3SuuWIoy9DsAZv6a5oMvjek=" class="card-img-top" alt="..." style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Faucets(Aria)</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve top fitted</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:18,550</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "18rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/603267744/photo/tapas-food.jpg?s=612x612&w=0&k=20&c=kHdCs_6B9YizzPkuynSpgJnTf8m37xsAQ6czvu0cXBU=" class="card-img-top" alt="..." style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Florentine(Bidet)</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with wall mounted disc</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:21,500</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "18rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1333075831/photo/friends-having-wine-tasting-or-celebrating-event-with-wine.jpg?s=612x612&w=0&k=20&c=TjetNlcdR17W0Ny0M7Xwx6hP7RVzdCaR1jQwpO-_xDk=" class="card-img-top" alt="..." style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Faucets(Accessories)</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve Stop</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25,000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
