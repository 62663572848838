import React from 'react'

export default function JaquarInfo() {
  return (
   <>
   

  

   <div className='container'>
    <div className='row mt-4 d-flex justify-content-center'>
        <div className='col-12 col-md-8 col-lg-5'>
            <img 
                src='https://media.istockphoto.com/id/518867858/photo/birthday-party-in-the-office.jpg?s=612x612&w=0&k=20&c=wFzZ_otUqJz_DAHGJtwbt-5NpGWz89Df6J0_IuZfTF8=' 
                style={{borderRadius:"10px", width:"100%", height:"auto"}}
                alt="Bathroom"
            />
        </div>
        <div className='col-12 col-md-6 col-lg-2'>
            {/* You can put content here if needed */}
        </div>
        <div className='col-12 col-md-8 col-lg-5'>
            <h3 style={{color:"#ff004f"}}>
                Birthday Party
            </h3>
            <p style={{color:"gray", fontFamily:"Poppins"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar luctus sem, eget porta orci. Maecenas molestie dui id diam feugiat, eu tincidunt mauris aliquam. Duis commodo vitae ligula et interdum. Maecenas faucibus mattis imperdiet. In rhoncus ac ligula id ultricies.
            </p>
        </div>
    </div>
</div>



    <div className='container'>
    <div className='row mt-3'>
        <div className='col-lg-4 mb-2'>
        <img src='https://media.istockphoto.com/id/1074986054/photo/group-of-children-at-birthday-party-at-home.jpg?s=612x612&w=0&k=20&c=ggIzZGu_Ti3Evr5ebSOwoDfEPgU2DON3lXWKhRFUEuU=' style={{borderRadius:"10px" ,width:"100%" ,height:"250px"}}></img>

        </div>

        <div className='col-lg-4 mb-2'>
        <img src='https://media.istockphoto.com/id/1188908240/photo/children-with-cake-standing-around-table-on-birthday-party-in-garden-in-summer.jpg?s=612x612&w=0&k=20&c=0Ut_aABoXmF2ompluh5AmtROnK2dfPCGGXfUdixOYU8=' style={{borderRadius:"10px" ,width:"100%" ,height:"250px"}}></img>

        </div>

        <div className='col-lg-4'>
        <img src='https://media.istockphoto.com/id/1479789881/photo/boy-blowing-birthday-candles-on-a-birtday-party.jpg?s=612x612&w=0&k=20&c=DmuBMp2iEEF1CyLq243cYEWozAvr_P6kpNMqTc96tUw=' style={{borderRadius:"10px" ,width:"100%",height:"250px"}}></img>

        </div>

        </div>      {/*  row div end here */}
    </div>       {/*  container div end here */}



   </>
  )
}
