import React from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

export default function Brands() {
  return (
   <>
   <div className='container'>
    <div className='row d-flex justify-content-evenly mt-3'>

          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}> < img src='https://media.istockphoto.com/id/1295633127/photo/grilled-chicken-meat-and-fresh-vegetable-salad-of-tomato-avocado-lettuce-and-spinach-healthy.jpg?s=612x612&w=0&k=20&c=Qa3tiqUCO4VpVMQDXLXG47znCmHr_ZIdoynViJ8kW0E='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b>Birthday</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
              <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/628250032/photo/roast-chicken-fillets-and-vegetables.jpg?s=612x612&w=0&k=20&c=Mr7ukhf9pHGINXL_tnNeyxnX83zPdu1OGTj9Rb_jkIE='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
              <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b>Wedding</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/1333127665/photo/chicken-biryani-spicy-indian-malabar-biryani-hyderabadi-biryani-dum-biriyani-pulao-golden.jpg?s=612x612&w=0&k=20&c=63UXYPOISm8nJ8SNK79dDm0w1gY6jXzYQP0heL6fnOg='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b>Puja</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/187248625/photo/pepperoni-pizza.jpg?s=612x612&w=0&k=20&c=QHrM65XqDQd3Z50r5cT2qV4nwctw6rNMM1JTlGEEVzI='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b>Baby Shower</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/966934632/photo/appetizing-french-fries-in-a-bowl.jpg?s=612x612&w=0&k=20&c=6mKRNQ-PWQRQg0RAXyCQZeRZRoSzohqVWhT-SKVXd04='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b>Functions</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/467416670/photo/huge-grass-fed-bison-hamburger-with-chips-beer.jpg?s=612x612&w=0&k=20&c=NA5S3gfJYRydMViaUMHz2d7wHuexygVM02xkiaE2p3c='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b>Events</b></Link></div>
            </div>
          </div>
    </div>
   </div>
   </>
  )
}
