import React from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

export default function MenuBar() {
  return (
   <>
   <div className='container'>
    <div className='row d-flex justify-content-evenly mt-3'>

          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/washB'}><img src='https://media.istockphoto.com/id/1470284906/photo/heart-shape-of-ketogenic-low-carbs-diet-concept-ingredients-for-healthy-foods-selection-on.jpg?s=612x612&w=0&k=20&c=MNDXzGMA223-Uwt8NHjv8Y6IwVHUev3Uw2djS3i_eR8='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/washB'} className="nav-link" ><b>Starter </b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
              <div class="p-2 d-flex justify-content-evenly"> <Link to={'/washB'}><img src='https://media.istockphoto.com/id/1331647832/photo/immune-boosting-vegan-health-food.jpg?s=612x612&w=0&k=20&c=b5mak-zXuEadTJD4-skwh325Q-DiDO046N_wn4w6q9E='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
              <div class="p-0" style={{ textAlign: "center" }}><Link to={'/washB'} className="nav-link" ><b>Soups</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/washB'}><img src='https://media.istockphoto.com/id/1319095436/photo/ingredients-in-a-small-glass-of-herbal-tea-ingredients-are-ginger-basil-cardamom-cloves-on.jpg?s=612x612&w=0&k=20&c=6YdQcyph-cYnf3IVevosYaxtvA4F7p_4GOeivGxlMCA='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/washB'} className="nav-link" ><b>Beverages</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/washB'}><img src='https://media.istockphoto.com/id/1319095436/photo/ingredients-in-a-small-glass-of-herbal-tea-ingredients-are-ginger-basil-cardamom-cloves-on.jpg?s=612x612&w=0&k=20&c=6YdQcyph-cYnf3IVevosYaxtvA4F7p_4GOeivGxlMCA='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/washB'} className="nav-link" ><b>Combo Pack</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"> <Link to={'/washB'}><img src='https://media.istockphoto.com/id/1193396586/photo/white-cup-of-healthy-rosemary-tea-pouring-from-teapot-with-fresh-rosemary-bunch-on-white.jpg?s=612x612&w=0&k=20&c=R9MuAX_P4RZbaKlMPUH3d7Kd-nP-adJJRdJ7OCwm7zE='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/washB'} className="nav-link" ><b>Biryani</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/washB'}><img src='https://media.istockphoto.com/id/618635048/photo/make-up-kit.jpg?s=612x612&w=0&k=20&c=zbOa-hE03pvpNOSDWLVxpXEe8hknPmVJULOa-ZPqtFo='  class="transition-image" alt='' style={{ height: "100px", width: "100px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/washB'} className="nav-link" ><b>Veg Thali</b></Link></div>
            </div>
          </div>
    </div>
   </div>
   </>
  )
}
